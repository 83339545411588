<template>
    <fieldset>
        <legend class="block font-semibold text-gray-900 mb-1">
            <InfoModal :title="attribute.title" :body="attribute.description" v-if="attribute.description"
                >{{ attribute.name }}
            </InfoModal>

            <span v-else>
                {{ attribute.name }}
            </span>
        </legend>
        <div
            v-for="(option, optionIdx) in attribute.values"
            :key="option.id"
            class="flex items-center select-none py-1"
            :class="!option.count ? 'opacity-50' : ''"
        >
            <input
                :id="`${attribute.id + (category ?? 0)}-${optionIdx}`"
                :name="`${attribute.id}[]`"
                :value="filterbale ? `${attribute.id}-${option.id}` : option.id"
                v-model="values"
                @change="$emit('update:value', values)"
                type="checkbox"
                class="h-5 w-5 border-gray-300 rounded transition focus:ring-1"
                :class="{
                    'hover:border-gray-600': option.count,
                    'border-transparent': values.includes(filterbale ? `${attribute.id}-${option.id}` : option.id)
                }"
                :disabled="!option.count"
            />
            <label
                :for="`${attribute.id + (category ?? 0)}-${optionIdx}`"
                class="ml-3 text-gray-600"
                :class="option.count ? 'cursor-pointer hover:text-gray-900' : ''"
            >
                <span v-if="option.name == 1">Ja</span>
                <span v-else-if="option.name == 0">Nee</span>
                <span v-else v-html="option.name"></span>
            </label>
            <div
                class="ml-4 relative pt-0.5 text-gray-600 text-xs rounded-full bg-gray-100 w-5 h-5 text-center flex justify-center"
            >
                {{ option.count }}
            </div>
        </div>
    </fieldset>
</template>

<script>
export default {
    props: {
        attribute: Object,
        value: Object,
        filterbale: Boolean,
        category: Boolean,
    },

    data() {
        return {
            values: [],
        };
    },

    created() {
        this.values = this.value;
    },
};
</script>
