<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="40" viewBox="0 0 57 40" class="w-14 md:w-10 inline-block">
        <path fill="#efD70d" d="M13.59 1.17c4.78-1.69 10.21-1.37 14.76.9.99.5 1.94 1.07 2.83 1.73a16.84 16.84 0 0 0-5.98 4.43c-2.93-1.5-6.46-1.73-9.54-.58-2.48.89-4.64 2.64-6.08 4.88-.93 1.44-1.56 3.09-1.82 4.79-2.25-.43-4.51-.82-6.76-1.27.66-3.69 2.42-7.16 4.97-9.88 2.1-2.25 4.74-3.98 7.62-5Z"/>
        <path fill="#efD70d" d="M28.52 10.71c1.26-1.98 2.86-3.73 4.68-5.19 2.77 2.67 4.72 6.21 5.51 10a293.62 293.62 0 0 1-7 1.52c-.41-2.38-1.56-4.6-3.19-6.33Z"/>
        <path fill="#67b03d" d="M31.18 3.8c2.68-1.36 5.66-2.16 8.66-2.21-2.34 1.06-4.62 2.32-6.64 3.93-1.82 1.46-3.43 3.21-4.69 5.19-1.87 2.88-3.04 6.17-3.96 9.47-.55 2.02-1.01 4.07-1.43 6.13h-4.92L9.76 13.08h6c1.16 1.76 2.32 3.52 3.47 5.29 1.17-3.8 3.22-7.31 5.97-10.13a19.24 19.24 0 0 1 5.98-4.44Z"/>
        <path fill="#cad129" d="M31.71 17.05 38.71 15.53c.89 4.2.37 8.69-1.45 12.57-1.58 3.4-4.13 6.35-7.27 8.34l-3.96-6.59c1.14-.7 2.17-1.58 3.02-2.62 2.33-2.76 3.34-6.61 2.66-10.18Z"/>
        <path fill="#e49219" d="M1 16.05c2.25.45 4.5.84 6.75 1.27-.5 3.03.2 6.23 1.88 8.78l-6.07 3.84C1.03 25.85.13 20.79 1 16.05Z"/>
        <path fill="#d1380e" d="M3.55 29.94 9.63 26.1c1.19 1.83 2.86 3.32 4.8 4.28l-4.54 9.02a24.33 24.33 0 0 1-8.32-3.06c-.5-.28-1-.57-1.47-.91 2.24-.38 4.47-.86 6.64-1.55-1.23-1.15-2.29-2.5-3.19-3.94Z"/>
        <path fill="#94be1c" d="M19.88 31.63c2.16-.03 4.31-.63 6.15-1.78l3.96 6.59a16.52 16.52 0 0 1-4.41 2.06c-1.85.63-3.76 1.05-5.69 1.31-.01-2.73 0-5.45-.01-8.18Z"/>
        <path fill="#de6926" d="M14.43 30.38c1.69.85 3.57 1.27 5.45 1.25 0 2.73-.01 5.46 0 8.19-3.32.41-6.71.27-9.99-.42l4.54-9.02Z"/>
    </svg>
</template>