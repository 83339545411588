<template>
    <picture v-if="image">
        <div v-if="base64SVG && !isImageLoaded" class="placeholder h-full" :style="placeholderStyle"></div>
        <source :srcset="image.webp_src || image.src" type="image/webp" />
        <img v-bind="commonImageProps" :width="image.width" :height="image.height" v-if="image.width || image.height" @error="handleImageError" />
        <img v-bind="commonImageProps" v-else @error="handleImageError" />
    </picture>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    image: {
        type: Object,
        default: null,
        required: true,
    },
    name: {
        type: String,
        default: '',
    },
    style: {
        type: String,
        default: '',
    },
    notlazy: {
        type: Boolean,
        default: false,
    },
    width: {
        type: Number,
    },
    height: {
        type: Number,
    },
    fph: {
        type: Boolean,
        default: false,
    },
});

// Extract base64svg from image.webp_src
const base64SVG = props.image ? props.image.base64_svg : null;

// Reactive property to track image loading state
const isImageLoaded = ref(false);

// Separate placeholder style from main image style
const placeholderStyle = computed(() => ({
    backgroundImage: base64SVG ? `url(${base64SVG})` : `url(${base64SVG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}));

// Handle image loading errors
function handleImageError(event) {
    event.target.src = props.image.origin_src; // Provide a fallback image
}

// Compute common image properties
const commonImageProps = computed(() => ({
    srcset: props.image.png_src ?? props.image.origin_src,
    src: props.image.origin_src,
    alt: props.name,
    width: props.width,
    height: props.height,
    class: ['max-h-full', props.style],
    decoding: props.fph ? 'auto' : 'async',
    fetchpriority: props.fph ? 'high' : props.notlazy ? 'auto' : 'low',
    loading: props.notlazy ? 'eager' : 'lazy',
    // sizes: '(max-width: 600px) 480px, 800px', // Example sizes attribute for responsive images
}));
</script>

<style scoped>

.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    filter: blur(10px);
    transform: scale(1.1);
    z-index: -1;
}
</style>