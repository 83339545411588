<template>
    <div class="rounded-lg w-full overflow-hidden table-hover shadow-md bg-white mb-6">
        <div class="p-8 border-b border-slate-100">
            <h1 class="text-xl font-bold block w-full">{{ title }}</h1>
        </div>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props:{
        title:String
    },
}

</script>