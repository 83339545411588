<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <Menu as="div" class="relative z-20 inline-block text-left" v-slot="{ open }">
        <div>
            <MenuButton
                 :class="[open ? 'text-gray-900' : 'text-gray-500', 'border-transparent transition-colors duration-100 hover:bg-gray-50 focus:bg-white hover:text-gray-700 inline-flex items-center px-3 py-2 font-medium focus:outline-hidden rounded-lg']"
            >
                {{ title }}
                <fa :icon="['fal', 'angle-down']" class="-mr-1 ml-1.5 h-4 w-4" />
            </MenuButton>
        </div>

        <transition name="drop">
            <MenuItems
                class="overflow-hidden origin-top-left absolute left-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black/10 focus:outline-hidden"
            >
                <div class="pt-2">
                   <slot></slot>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
export default {
    props: ['title'],

    setup() {
        return {};
    },
};
</script>
